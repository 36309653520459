export function useUtils() {
  function debounce<T extends (...args: (string | number)[]) => Promise<void>>(fn: T, ms = 300) {
    let timeoutId: ReturnType<typeof setTimeout>

    return function (this: ThisParameterType<T>, ...args: Parameters<T>) {
      clearTimeout(timeoutId)
      timeoutId = setTimeout(() => fn.apply(this, args), ms)
    }
  }

  return {
    debounce,
  }
}
